const LANG = {
    dropHere: {
        fi: `Pudota tiedostosi tähän`,
        en: `Drop your file here`
    },
    dropHereOrClick: {
        fi: `...tai klikkaa valitaksesi tiedosto`,
        en: `...or click to choose a file`
    },
    wait: {
        fi: `Hetki, taikoja suoritetaan...`,
        en: `Wait, doing the magic...`
    },
    extractionCode: {
        fi: `Työkirjan purku palauttaa koodin `,
        en: `Extracting the file returns code `
    },
    extractionSuccess: {
        fi: `Työkirjan purku onnistuu virheettä`,
        en: `The file was extracted succesfully`
    },
    missingPageList: {
        fi: `Työkirjan rakennekuvaus pages.json puuttuu`,
        en: `The page list file pages.json is missing`
    },
    existsPageList: {
        fi: `Työkirjassa on rakennekuvaus (pages.json)`,
        en: `The file contains a page list (pages.json)`
    },
    missingMeta: {
        fi: `Työkirjan metatiedosto worksheet.json puuttuu`,
        en: `The meta file worksheet.json is missing`
    },
    existsMeta: {
        fi: `Työkirjassa on metatiedosto (worksheet.json)`,
        en: `The file contains a meta file (worksheet.json)`
    },
    missingImages: {
        fi: `Työkirjan kuvatiedostot puuttuvat`,
        en: `The image folder is missing`
    },
    existsImages: {
        fi: `Työkirjassa on kuvakansio (images)`,
        en: `The image folder exists`
    },
    missingPages: {
        fi: `Työkirjan sivut puuttuvat, tiedostoa ei voida palauttaa`,
        en: `The pages folder is missing, it is impossible to repair this file`
    },
    existsPages: {
        fi: `Työkirjassa on sivukansio (pages)`,
        en: `The pages folder exists`
    },
    pagesFailed: {
        fi: `Työkirjan rakennekuvausta ei voida lukea`,
        en: `Unable to read page list`
    },
    metaFailed: {
        fi: `Työkirjan metatietoja ei voida lukea`,
        en: `Unable to read metadata`
    },
    saveVersion: {
        fi: `Tiedosto on tallennettu versiolla `,
        en: `The file was saved with `
    },
    pageListRebuild: {
        fi: `Työkirjan sivulistaus on korjattava`,
        en: `The page list requires rebuilding`
    },
    pageMissingRebuild: {
        fi: `Sivu %1 puuttuu, sivulistaus on korjattava`,
        en: `Page %1 is missing, page list will be rebuilt`
    },
    validatingPage: {
        fi: `Tarkistetaan sivu `,
        en: `Validating page `
    },
    brokenPage: {
        fi: `Sivu %1 on rikki`,
        en: `Page %1 is invalid`
    },
    repairingPageId: {
        fi: `Sivun %1 id korjataan (%2)`,
        en: `Page %1 id will be repaired (%2)`
    },
    lastPageInvalid: {
        fi: `Työkirjan viimeisin sivu viittaa puuttuvaan sivuun`,
        en: `Invalid recent page`
    },
    copyingImages: {
        fi: `Kopioidaan kuvia...`,
        en: `Copying images...`
    },
    copyingImagesFailed: {
        fi: `Kuvien kopiointi epäonnistui`,
        en: `Copying images failed`
    },
    fixingIndex: {
        fi: `Korjataan rakennekuvausta...`,
        en: `Fixing page list index...`
    },
    fixingMeta: {
        fi: `Korjataan metatietoja...`,
        en: `Fixing metadata...`
    },
    checksDone: {
        fi: `Tarkistukset suoritettu, lataa tiedosto alta`,
        en: `Checks done, download the file below`
    },
    noProblems: {
        fi: `Automaattitarkistus ei havainnut ongelmia`,
        en: `Automatic checks didn't find any issues`
    },
    fileReady: {
        fi: `Tiedostosi on tarkistettu ja korjattu!`,
        en: `Your file has been checked and repaired`
    },
    fileReadySub: {
        fi: `Jos se oikuttelee edelleen, lähetä se suoraan kehittäjälle`,
        en: `If issues continue, please send it to the developer`
    },
    download: {
        fi: `Lataa tiedosto`,
        en: `Download file`
    },
    downloadImages: {
        fi: `Lataa kuvat`,
        en: `Download images`
    },
    repairOther: {
        fi: `Korjaa uusi`,
        en: `Repair other file(s)`
    }
};

export default function _lang(key) {
    if ((window.location?.hash ?? '') == '#/en') {
        return (LANG[key] ?? {})['en'] ?? `translation.en.${key}`;
    }
    return (LANG[key] ?? {})['fi'] ?? `translation.fi.${key}`;
}