import SevenZip from '7z-wasm';
import JSZip from 'jszip';
import download from 'downloadjs';
import _lang from './lang';

const oldLog = window.console.log;
const _console = { log: (...args) => oldLog.apply(window.console, args) };
const CONSOLE_LISTEN = [];
window.console.log = function(...args) {
    CONSOLE_LISTEN.forEach(f => f(args));
    oldLog.apply(window.console, args);
}

$(() => {
    
    let sevenZip;
    
    if ((window.location?.hash ?? '') == '#/en') {
        $('[data-en]').each((i, el) => {
            console.log(el);
            $(el).attr('data-fi', $(el).text());
            $(el).text($(el).attr('data-en'));
        })
    }
    
    $('[data-lang]').on('click', e => {
        e.preventDefault();
        window.location.href = $(e.target).attr('href');
        window.location.reload();
    });
    
    const $base = $('#base');
    const $input = $('<input type="file" accept=".lma" multiple="false"/>')
        .css('display', 'none')
        .on('input', e => {
            const files = e.target.files;
            handleFiles(files)
                .catch(err => {
                    console.error(err);
                    $base.append($('<div class="alert alert-danger"/>').text(err.message))
                });
        })
        .appendTo($base);
    
    $(document).bind('dragover', e => {
        e.preventDefault();
    })
    
    const $drop = $('<div class="app-file-drop"/>')
        .append(`<p class="mb-0">${_lang('dropHere')}<br><small>${_lang('dropHereOrClick')}</small></p>`)
        .appendTo($base);
        
    $drop.on('click', e => {
        e.preventDefault();
        $input.click()
    });
    
    $drop.on('dragover', e => {
        e.preventDefault();
        $drop.toggleClass('hovering', true)
    });
    
    $drop.on('dragenter', e => {
        e.preventDefault();
        $drop.toggleClass('hovering', true)
    });
    
    $drop.on('drop', e => {
        e.preventDefault();
        if (e.originalEvent.dataTransfer && e.originalEvent.dataTransfer.files.length > 0) {
            handleFiles(e.originalEvent.dataTransfer.files)
                .catch(err => {
                    console.error(err);
                    $base.append($('<div class="alert alert-danger"/>').text(err.message))
                });
        }
        $drop.toggleClass('hovering', false)
    });
    
    $drop.on('dragleave dragexit', e => {
        e.preventDefault();
        $drop.toggleClass('hovering', false)
    });
    
    const $errors = $('<div class="app-log"/>')
        .appendTo($base)
        .hide();
    
    const $loading = $('<div class="p-4 text-center"/>')
        .append($('<div class="spinner-border text-secondary"/>'))
        .append(`<p class="mt-2 mb-0"><small><i>${_lang('wait')}</i></small></p>`)
        .appendTo($base)
        .hide();
    
    async function handleFiles(files) {
        
        _console.log(files);
        
        $drop.hide();
        $loading.show();
        $errors.show();
        
        if (!sevenZip) {
            sevenZip = await SevenZip();
            _console.log(`7z loaded`);
        }
        
        const f = files[0];
        _console.log(`${f.name}`);
            
        const fileName = `${f.name ?? `archive${Date.now()}${Math.random()}`}.zip`;
        const data = new Uint8Array(await f.arrayBuffer());
        
        const fixed = new JSZip();
        fixed.folder('pages');
        fixed.folder('images');
        
        _console.log(`-> Writing to virtual FS...`);
        const stream = sevenZip.FS.open(fileName, 'w+');
        sevenZip.FS.write(stream, data, 0, data.length);
        sevenZip.FS.close(stream);
        
        const sevenZipOutput = [];
        const waitConsoleOutput = (...args) => sevenZipOutput.push(...args);
        CONSOLE_LISTEN.push(waitConsoleOutput);
        const exitCode = sevenZip.callMain(['x', fileName, '-ofileout', '-aoa']);
        CONSOLE_LISTEN.splice(CONSOLE_LISTEN.indexOf(waitConsoleOutput), 1);
        
        chownRecursive(sevenZip.FS, 'fileout');
        _console.log(sevenZipOutput.join('\n'));
        
        let hadAnyIssues = false;
        let requiresPageIndexRebuild = false;
        let requiresMetaRebuild = false;
        
        if (exitCode != 0) {
            showProgress('alert-triangle', `${_lang('extractionCode')} ${exitCode}`);
            hadAnyIssues = true;
        } else {
            showProgress('check', `${_lang('extractionSuccess')}`, 'success');
        }
        
        const tempFiles = sevenZip.FS.readdir('fileout');
        _console.log(tempFiles);
        
        await wait(100);
        if (tempFiles.indexOf('pages.json') == -1) {
            showProgress('alert-triangle', _lang('missingPageList'));
            hadAnyIssues = true;
            requiresPageIndexRebuild = true;
        } else {
            showProgress('check', _lang('existsPageList'), 'success');
        }
        
        await wait(100);
        if (tempFiles.indexOf('worksheet.json') == -1) {
            showProgress('alert-triangle', _lang('missingMeta'));
            hadAnyIssues = true;
            requiresMetaRebuild = true;
        } else {
            showProgress('check', _lang('existsMeta'), 'success');
        }
        
        await wait(100);
        if (tempFiles.indexOf('images') == -1) {
            showProgress('image', _lang('missingImages'));
            hadAnyIssues = true;
        } else {
            showProgress('check', _lang('existsImages'), 'success');
        }
        
        await wait(100);
        if (tempFiles.indexOf('pages') == -1) {
            showProgress('alert-triangle', _lang('missingPages'), 'danger');
            hadAnyIssues = true;
            $loading.hide();
            return;
        } else {
            showProgress('check', _lang('existsPages'), 'success');
        }
        
        let pages = [];
        let meta = {};
        
        try {
            const pagesRaw = sevenZip.FS.readFile(`fileout/pages.json`, { encoding: 'utf8' });
            pages = [...JSON.parse(pagesRaw)];
        } catch (err) {
            console.error(err);
            showProgress('alert-triangle', _lang('pagesFailed'));
            hadAnyIssues = true;
        }
        await wait(100);
        
        try {
            const metaRaw = sevenZip.FS.readFile(`fileout/worksheet.json`, { encoding: 'utf8' });
            meta = {...JSON.parse(metaRaw)};
        } catch (err) {
            console.error(err);
            showProgress('alert-triangle', _lang('metaFailed'));
            hadAnyIssues = true;
        }
        await wait(100);
        
        const pageFiles = sevenZip.FS.readdir(`fileout/pages/`);
        
        if (meta && meta.latestVersion) {
            showProgress('info', `${_lang('saveVersion')} ${meta.latestVersion}`, 'secondary');
        }
        
        if (pages.length == 0 || (new Set(pages)).size != pages.length) {
            showProgress('alert-triangle', _lang('pageListRebuild'));
            requiresPageIndexRebuild = true;
            hadAnyIssues = true;
        }
        
        const availablePages = [];
        
        for (const pageId of pages) {
            
            await wait(100);
            
            if (pageFiles.indexOf(`${pageId}.json`) == -1) {
                showProgress('alert-triangle', _lang('pageMissingRebuild').replace('%1', pageId));
                requiresPageIndexRebuild = true;
                hadAnyIssues = true;
                continue;
            }
            
            let pageData = {};
            showProgress('chevron-right', `${_lang('validatingPage')} ${pageId}...`, 'secondary');
            
            try {
                const pageRaw = sevenZip.FS.readFile(`fileout/pages/${pageId}.json`, { encoding: 'utf8' });
                pageData = {...JSON.parse(pageRaw)};
            } catch (err) {
                showProgress('alert-triangle', _lang('brokenPage').replace('%1', pageId));
                requiresPageIndexRebuild = true;
                hadAnyIssues = true;
                continue;
            }
            
            if (pageData.id != pageId) {
                showProgress('alert-triangle', _lang('repairingPageId').replace('%1', pageId).replace('%2', pageData.id));
                pageData.id = pageId;
                requiresPageIndexRebuild = true;
                hadAnyIssues = true;
            }
            
            availablePages.push(pageId);
            fixed.file(`pages/${pageId}.json`, JSON.stringify(pageData));
            
        }
        
        if (meta.currentPageId && availablePages.indexOf(`${meta.currentPageId}.json`) == -1) {
            showProgress('alert-triangle', _lang('lastPageInvalid'));
            requiresMetaRebuild = true;
            hadAnyIssues = true;
        }
        
        showProgress('image', _lang('copyingImages'), 'secondary');
        try {
            const imageFiles = sevenZip.FS.readdir(`fileout/images/`);
            for (const file of imageFiles) {
                if (file == '.' || file == '..') continue;
                const imageData = sevenZip.FS.readFile(`fileout/images/${file}`);
                fixed.file(`images/${file}`, imageData);
            }
        } catch (err) {
            console.error(err);
            showProgress('alert-triangle', _lang('copyingImagesFailed'));
        }
        
        if (requiresPageIndexRebuild) {
            showProgress('edit', _lang('fixingIndex'), 'secondary');
            fixed.file('pages.json', JSON.stringify(availablePages));
        } else {
            fixed.file('pages.json', JSON.stringify(pages));
        }
        
        if (requiresMetaRebuild) {
            showProgress('edit', _lang('fixingMeta'), 'secondary');
            fixed.file('worksheet.json', JSON.stringify({
                title: 'Korjattu työkirja',
                description: `LMAFix^2:lla korjattu työkirja`,
                author: '',
                created: '',
                protected: false,
                password: '',
                pVersion: { version: 'r1.9' },
                theme: 'light',
                latestVersion: 'r1.9'
            }));
        } else {
            fixed.file('worksheet.json', JSON.stringify(meta));
        }
        
        showProgress('check', _lang('checksDone'), 'success');
        if (!hadAnyIssues) {
            showProgress('info', _lang('noProblems'), 'secondary')
        }
        
        $loading.hide();
        
        $base.append($('<div class="text-center mt-4"/>').append(
            $(`<h4 class="mb-0">${_lang('fileReady')}</h4>`),
            $(`<p class="mb-3">${_lang('fileReadySub')}</p>`),
            $('<button class="btn btn-success"/>')
                .text(_lang('download'))
                .prepend(`<i data-feather="download" class="me-1"/>`)
                .on('click', e => {
                    fixed.generateAsync({ type: 'blob', mimeType: 'application/x-lmath' })
                        .then(zipFile => {
                            download(zipFile, `fixed_${(new Date()).toISOString()}.lma`, 'application/x-lmath');
                        })
                        .catch(err => {
                            console.error(err);
                            $errors.append(
                                $('<div class="alert alert-danger"/>').text(err)
                            )
                        })
                }),
            $('<div class="mt-2"/>').append(
                $('<button class="btn btn-secondary btn-sm"/>')
                    .text(_lang('downloadImages'))
                    .prepend(`<i data-feather="download" class="me-1"/>`)
                    .on('click', e => {
                        
                        const zipImages = new JSZip();
                        
                        try {
                            const imageFiles = sevenZip.FS.readdir(`fileout/images/`);
                            for (const file of imageFiles) {
                                if (file == '.' || file == '..') continue;
                                const imageData = sevenZip.FS.readFile(`fileout/images/${file}`);
                                zipImages.file(`${file}.png`, imageData);
                            }
                        } catch (err) {
                            console.error(err);
                            showProgress('alert-triangle', _lang('copyingImagesFailed'));
                        }
                        
                        zipImages.generateAsync({ type: 'blob', mimeType: 'application/zip' })
                            .then(zipFile => {
                                download(zipFile, `images_${(new Date()).toISOString()}.zip`, 'application/x-lmath');
                            })
                            .catch(err => {
                                console.error(err);
                                $errors.append(
                                    $('<div class="alert alert-danger"/>').text(err)
                                )
                            })
                        
                    }),
                $('<button class="btn btn-secondary btn-sm ms-1"/>')
                    .text(_lang('repairOther'))
                    .prepend(`<i data-feather="refresh-cw" class="me-1"/>`)
                    .on('click', e => {
                        window.location.reload();
                    })
            )
        ));
        feather.replace();
        
    }
    
    function showProgress(icon, content, errorType = 'warning') {
        $errors.append(
            $(`<div class="text-${errorType ?? 'warning'}"/>`).append(
                $('<div class="d-flex align-items-center"/>').append(
                    $('<div class="me-1"/>').append($('<i/>').attr('data-feather', icon)),
                    $('<div/>').append(typeof content === 'string' ? $('<span/>').text(content) : content)
                )
            )
        );
        feather.replace();
        $errors.scrollTop($errors[0].scrollHeight);
    }
    
    function chownRecursive(FS, path) {
        const files = FS.readdir(path);
        for (const file of files) {
            const filePath = path + '/' + file;
            if (file == '.' || file == '..') continue;
            console.log(filePath);
            FS.chmod(filePath, 777);
            if (filePath.endsWith('/images') || filePath.endsWith('/pages')) {
                chownRecursive(FS, filePath);
            }
        }
    }
    
    function wait(ms) {
        return new Promise((resolve, reject) => setTimeout(resolve, ms));
    }
    
    feather.replace();
    
});